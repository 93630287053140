/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDollarSign } from "@fortawesome/free-solid-svg-icons"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import MediaQuery from "react-responsive"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import {
  formatter,
  logger,
  imageDistributed,
  getThemeColors,
} from "../../helpers"
import { Modal } from "../../components/Modal"
import { Tabs } from "../../components/Tabs"
import {
  postWithdraw,
  postTip,
  getPSPCheckoutURL,
  getWalletWithCurrency,
} from "../../actions/wallet"
import Prompt2FAPINCard from "../../components/Prompt2FAPINCard"
import formSchema from "../../model/withdrawSchema"
import tipSchema from "../../model/tipSchema"
import Spinner from "../../components/Spinner"
import errorCodes from "../../constants/error-codes"
import { toast } from "../../components/Toast"
import { Accordion } from "../../components/Accordion"
import Input from "../../components/Input"
import Button from "../../components/Button"
import Alert from "../../components/Alert"
// import checkMarkAsset from "../../assets/icons/check-mark.svg"
import creditCardAsset from "../../assets/images/depositAssets/credit-card-asset.png"
import crazySav from "../../assets/images/depositAssets/crazy-sav-asset.png"
import eth from "../../assets/images/depositAssets/eth.png"
import btc from "../../assets/images/depositAssets/btc.png"
import usdt from "../../assets/images/depositAssets/usdt.png"
import ltc from "../../assets/images/depositAssets/ltc.png"
/* import bch from "../../assets/images/depositAssets/bch.png"
 */ import doge from "../../assets/images/depositAssets/doge.png"
/* import eos from "../../assets/images/depositAssets/eos.png"
 */ import trx from "../../assets/images/depositAssets/trx.png"
/* import xrp from "../../assets/images/depositAssets/xrp.png"
 */
// TODO : use the Link component or fix the existent to use it later everywhere in the codebase

import { getUser, getCountryCode } from "../../actions/user"
import CustomIframe from "../../components/CustomIframe"
// import themes from "../../themes"
// import dodgeCoinImage from "../../assets/icons/doge-coin.svg"
import visaAsset from "../../assets/images/home/visa-asset.png"
import visaVectorAsset from "../../assets/images/home/visa-vector-asset.png"
import thumbAsset from "../../assets/images/home/thumb-asset.png"
import applePayAsset from "../../assets/images/home/apple-pay-asset.png"

const serviceName = "cashier"

const tabIndexes = {
  deposit: 0,
  "buy-crypto": 1,
  withdraw: 2,
  tip: 3,
}

const PaymentMethodContext = React.createContext(null)

const cryptoPaymentMethods = [
  {
    method: "eth",
    label: "ETH",
    subLabel: "",
    imageSource: eth,
  },
  {
    method: "litecoin",
    label: "LTC",
    subLabel: "",
    imageSource: ltc,
  },
  {
    method: "bitcoin",
    label: "BTC",
    subLabel: "",
    imageSource: btc,
  },
  {
    method: "trx",
    label: "TRX",
    subLabel: "",
    imageSource: trx,
  },
  {
    method: "tether",
    label: "USDT",
    subLabel: "",
    imageSource: usdt,
  },

  /*  {
    method: "bitcoin-cash",
    label: "BCH",
    subLabel: "",
    imageSource: bch,
  }, */
  {
    method: "doge",
    label: "DOGE",
    subLabel: "",
    imageSource: doge,
  },
  /* {
    method: "eos",
    label: "EOS",
    subLabel: "",
    imageSource: eos,
  }, */

  /*  {
    method: "xrp",
    label: "XRP",
    subLabel: "",
    imageSource: xrp,
  }, */
]

const flatPaymentMethods = [
  {
    method: "crazy-sav",
    label: "Crazysav",
    subLabel: "Save up to 100%",
    imageSource: crazySav,
  },
  {
    method: "instant-coupon",
    label: "Credit Card",
    subLabel: "Save up to 100%",
    imageSource: creditCardAsset,
  },
]

// const instantCouponURL = {
//   ultc: "https://buy.stripe.com/eVa7vDa2V3I246IcMM",
//   aryahi: "https://buy.stripe.com/5kA9AZ9zn3vSbe0bII",
//   aryahi1: "https://buy.stripe.com/6oEdRf7rf8Qc95SbIJ",
//   aryahi2: "https://buy.stripe.com/14keVe3lf2W43w49AA",
//   crazySav: "https://crazysav.com",
// }

const buyCryptoWithinFiveMinutesArticles = {
  fr: "https://help.casinocarbon.com/fr/articles/8473001-comment-acheter-des-crypto-monnaies-avec-casino-universe-en-moins-de-5-minutes",
  en: "https://help.casinocarbon.com/en/articles/8473001-how-can-i-buy-cryptocurrencies-with-casino-universe-in-less-than-5-minutes",
  sp: "https://help.casinocarbon.com/es/articles/8473001-como-comprar-criptomonedas-con-casino-universe-en-menos-de-5-minutos",
  it: "https://help.casinocarbon.com/it/articles/8473001-come-acquistare-criptovalute-con-casino-universe-in-meno-di-5-minuti",
}

const CheckRightAlert = ({ is2FAActive, isKycCompleted, isVerified }) => {
  if (!is2FAActive && isKycCompleted) {
    return (
      <Alert>
        <div className="flex justify-between items-center xs:px-2 md:px-5">
          <span className="xs:text-[10px] lg:text-sm">
            Your 2FA is not active. Please activate it by clicking :{" "}
          </span>
          <span>
            <Link
              className="text-tertiary xs:text-[10px] lg:text-sm"
              to="?service=account&tab=security"
            >
              Activate 2FA
            </Link>{" "}
          </span>
        </div>
      </Alert>
    )
  }
  if (is2FAActive && !isKycCompleted) {
    return (
      <Alert>
        <div className="flex justify-between items-center xs:px-2 md:px-5">
          <span className="xs:text-[10px] lg:text-sm">
            Your KYC is not completed. Please activate it by clicking :{" "}
          </span>
          <span>
            <Link
              className="text-tertiary xs:text-[10px] lg:text-sm"
              to="?service=account&tab=verification"
            >
              Complete KYC
            </Link>{" "}
          </span>
        </div>
      </Alert>
    )
  }
  if (!isVerified && is2FAActive && isKycCompleted) {
    return (
      <Alert>
        <div className="flex justify-between items-center xs:px-2 md:px-5">
          <span className="xs:text-[10px] lg:text-sm">
            Your email is not verified. Please activate it by clicking :{" "}
          </span>
          <span>
            <Link
              className="text-tertiary xs:text-[10px] lg:text-sm"
              to="?service=account&tab=security"
            >
              Verify your email
            </Link>{" "}
          </span>
        </div>
      </Alert>
    )
  }
  return <div />
}

CheckRightAlert.propTypes = {
  is2FAActive: PropTypes.bool.isRequired,
  isKycCompleted: PropTypes.bool.isRequired,
  isVerified: PropTypes.bool.isRequired,
}

const PaymentCard = ({ children, id, isCrazySavMethod }) => {
  const { activeMethod, setActiveMethod } = useContext(PaymentMethodContext)
  const handleSelectMethod = () => {
    setActiveMethod(id)
  }

  const methodStyle = (themeColors) => css`
    ${activeMethod === id
      ? `
    border: 4px solid ${themeColors.tertiary}; /* Dynamic tertiary color */
    z-index: 0;
  `
      : `
    border: 1px solid #d1d5db; /* Equivalent to border-gray-400 */
  `}
  `

  const themeColors = getThemeColors()

  return (
    <div
      onClick={handleSelectMethod}
      role="button"
      onKeyDown={Function.prototype}
      tabIndex={0}
      className={`relative bg-white flex justify-center items-center rounded-xl cursor-pointer z-10 ${
        isCrazySavMethod ? "h-[50px]" : "h-[70px]"
      }  px-1 `}
      css={methodStyle(themeColors)}
      id={id}
    >
      {children}
      <img
        fetchpriority="low"
        src={imageDistributed("checkMark")}
        alt="check-mark-asset"
        className={`${
          activeMethod === id
            ? "inline-block absolute -top-2  -right-2 z-50 rounded-full bg-white"
            : "hidden z-50"
        }`}
      />
    </div>
  )
}
PaymentCard.defaultProps = {
  isCrazySavMethod: false,
}

PaymentCard.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
  isCrazySavMethod: PropTypes.bool,
}

const borderStyle = (themeColors) => css`
  border-width: 4px; /* Equivalent to Tailwind's border-4 */
  border-style: solid; /* You need to define the style to use width in CSS */
  border-color: ${themeColors?.specialTertiary}; /* Dynamically set from themeColors */
`

const DepositTab = () => {
  // const { referrer } = useSelector((state) => state.user)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeMethod, setActiveMethod] = useState(null)

  const [pspCheckoutURL, setPspCheckoutURL] = useState("")
  const { refetch } = useQuery({
    queryKey: ["getPSPCheckoutURL"],
    queryFn: () => getPSPCheckoutURL(),
    refetchOnMount: "always",
    onSuccess: ({ data: pspCheckoutURLResponse }) => {
      setPspCheckoutURL(pspCheckoutURLResponse?.checkout_url)
    },
    onError: (error) => {
      logger(error)
      refetch()
    },
  })
  const context = useMemo(
    () => ({ activeMethod, setActiveMethod }),
    [activeMethod]
  )

  const handleOnClickEvent = () => {
    if (activeMethod === "crazy-sav" || activeMethod === "instant-coupon") {
      window.open(pspCheckoutURL, "_blank")
    } else {
      navigate(`/cashier/oxprocessing?method=${activeMethod}`)
    }
  }
  const handleRedirectToHistoryModal = () => {
    searchParams.set("service", "history")
    setSearchParams(searchParams)
  }
  const isContinueCtaActive = !activeMethod

  const themeColors = getThemeColors()

  const languageSelected = i18n.language?.split("-")[0]

  return (
    <Tabs.Body.Item>
      <PaymentMethodContext.Provider value={context}>
        <div className="w-full">
          <div className="my-2">
            <p className="text-sm  xs:text-base text-gray-800 ">
              {t(
                "We provides multiple payment methods and make sure that our third party payment providers do not use your data."
              )}
            </p>
            <p className="text-sm xs:text-base text-tertiary">
              <span className="font-bold text-gray-700">
                {t("The minimum amount to deposit is 30 USD.")}&nbsp;
              </span>
              <br />
              {/* <span className="font-bold">
                {t("Pick your payment method of choice and proceed !")}
              </span> */}
              <span className="text-gray-800">
                {t(
                  "If you don't have any crypto yet and need assistance, check out"
                )}
                <a
                  href={`${buyCryptoWithinFiveMinutesArticles[languageSelected]}`}
                  className="font-bold"
                  style={{ color: themeColors?.tertiary }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {t("this article")}
                </a>{" "}
                {t("to dive into the exciting world of cryptocurrencies!")}
              </span>
            </p>
          </div>
          <div className="mt-4 mb-3 flex flex-row xs:flex xs:flex-col xs:justify-center xs:items-center  ">
            <div
              className="desktop:w-full xs:w-full  rounded-xl px-3 py-4 border-4"
              style={{
                backgroundColor: themeColors?.lightTertiary,
              }}
              css={borderStyle(themeColors)}
            >
              <Accordion defaultActiveItem="pay-with-crypto-currency">
                <Accordion.Item key="pay-with-crypto-currency">
                  <Accordion.Item.Header
                    id="pay-with-crypto-currency"
                    cryptosAccordion
                  >
                    <div className="text-[18px] crazy-sav-payment-title text-specialPrimary  ml-4">
                      {t("Pay with Cryptocurrency")}
                    </div>
                  </Accordion.Item.Header>
                  <Accordion.Item.Content id="pay-with-crypto-currency">
                    <div className="payments-methods-cards my-4   xs:grid-cols-3 tablet:grid-cols-6  justify-center gap-3 pb-5 ">
                      {cryptoPaymentMethods.slice(0, 9).map((method) => (
                        <div key={method.label}>
                          <PaymentCard id={method.method} isCrazySavMethod>
                            <div className="flex justify-between items-center">
                              <img
                                fetchpriority="low"
                                loading="lazy"
                                decoding="async"
                                className="w-auto h-[31px] mr-2"
                                src={method.imageSource}
                                alt={method.label}
                              />
                              <div className="flex-col">
                                <p className="text-sm text-specialPrimary font-semibold  custom-payment-card-title ">
                                  {t(method.label)}
                                </p>
                                <p className="text-xs text-tertiary custom-payment-card-title">
                                  {method.subLabel}
                                </p>
                              </div>
                            </div>
                          </PaymentCard>
                        </div>
                      ))}
                    </div>
                  </Accordion.Item.Content>
                </Accordion.Item>
                {pspCheckoutURL && (
                  <Accordion.Item key="pay-with-crazy-sav">
                    <div className="mt-4 ">
                      <Accordion.Item.Header
                        id="pay-with-crazy-sav"
                        cryptosAccordion
                      >
                        <div className="text-[18px] text-specialPrimary  ml-4 flex  items-center ">
                          <span className="crazy-sav-payment-title">
                            {" "}
                            {t("Pay with CrazySav")}
                          </span>
                        </div>
                      </Accordion.Item.Header>

                      <Accordion.Item.Content id="pay-with-crazy-sav">
                        <div className="payments-methods-cards-crazy-sav grid my-4   xs:grid-cols-2 tablet:grid-cols-4 justify-center gap-3 pb-5 mt-5">
                          {flatPaymentMethods.map((method) => (
                            <div key={method.label}>
                              <PaymentCard id={method.method}>
                                <div className="flex justify-between items-center">
                                  <img
                                    fetchpriority="low"
                                    loading="lazy"
                                    decoding="async"
                                    className="w-auto h-[31px] mr-2"
                                    src={method.imageSource}
                                    alt={method.label}
                                  />
                                  <div className="flex-col">
                                    <p className="text-sm text-specialPrimary font-semibold custom-payment-card-title">
                                      {t(method.label)}
                                    </p>
                                  </div>
                                </div>
                              </PaymentCard>
                            </div>
                          ))}
                        </div>
                      </Accordion.Item.Content>
                    </div>
                  </Accordion.Item>
                )}
              </Accordion>
              <div className="mt-4 flex justify-end items-center">
                <Button.Primary
                  size="small"
                  onClick={handleOnClickEvent}
                  disabled={isContinueCtaActive}
                >
                  <span className="w-full font-semibold">{t("Continue")}</span>
                </Button.Primary>
                <Button.Secondary
                  size="small"
                  onClick={handleRedirectToHistoryModal}
                >
                  <span
                    className="font-semibold"
                    style={{ color: themeColors?.tertiary }}
                  >
                    {t("History")}
                  </span>
                </Button.Secondary>
              </div>
            </div>
          </div>
        </div>
      </PaymentMethodContext.Provider>
    </Tabs.Body.Item>
  )
}

const WithdrawTab = () => {
  const { isVerified } = useSelector((state) => state.auth)
  const { is2FAActive, kyc } = useSelector((state) => state.user)
  const { t } = useTranslation()

  const navigate = useNavigate()
  const [amount, setAmount] = useState("")
  const [address, setAddress] = useState("")
  const [showPrompt2FAPinCard, setShowPrompt2FAPinCard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { balance, fixedFees, percentageFees } = useSelector(
    (state) => state.wallet
  )
  const [activeMethod, setActiveMethod] = useState(null)
  const context = useMemo(
    () => ({ activeMethod, setActiveMethod }),
    [activeMethod]
  )

  const formOptions = { resolver: yupResolver(formSchema) }

  const keyPressHanlder = (event) => {
    const { key } = event
    setAmount((prevValue) => {
      const stringPrevvalue = prevValue.toString()
      return key !== "Backspace"
        ? !Number.isNaN(parseInt(key, 10)) || key === "," || key === "."
          ? prevValue + key
          : prevValue
        : prevValue
        ? stringPrevvalue.substring(0, stringPrevvalue.length - 1)
        : 0
    })
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm(formOptions)

  const calculateFees = () => amount * percentageFees + fixedFees

  const handleWithdraw = async (submitAmount, wallet, pin) => {
    setIsLoading(true)
    const responseWithdraw = await postWithdraw(submitAmount, wallet, pin)
    if (responseWithdraw.status === 200) {
      toast.success(
        t("Your money is on its way"),
        t("We are processing your withdrawal.")
      )
    } else if (
      responseWithdraw.status === 400 &&
      responseWithdraw.data.code === errorCodes["2FA_PIN_MISSING"]
    ) {
      setShowPrompt2FAPinCard(true)
    } else if (
      responseWithdraw.status === 400 &&
      responseWithdraw.data.code === errorCodes["2FA_NOT_ACTIVE"]
    ) {
      toast.error("2FA is not activate", "You need to activate 2FA first!")
      navigate("?service=account&tab=security")
      setAddress("")
      setAmount("")
    } else if (
      responseWithdraw.status === 401 &&
      responseWithdraw.data.code === errorCodes["2FA_PIN_INVALID"]
    ) {
      setIsLoading(false)
      toast.error(
        t("Two-Factor Authentication Failed"),
        t("Please provide a valid two-factor authentication PIN.")
      )
      setAddress("")
      setAmount("")
    } else if (
      responseWithdraw.status === 401 &&
      responseWithdraw.data.code === errorCodes.KYC_LEVEL_1_IS_NOT_COMPLETED
    ) {
      setIsLoading(false)
      toast.error(
        t("KYC Level 1 not complete !"),
        t("You need to complete KYC Level 1 to be able to withdraw.")
      )
      setAddress("")
      setAmount("")
    } else if (
      responseWithdraw.status === 401 &&
      responseWithdraw.data.code ===
        errorCodes.WITHDRAW_LESS_THAN_DEPOSITED_HALF_HAS_BEEN_WAGERED
    ) {
      setIsLoading(false)
      toast.error(
        t("Not enough bets"),
        t("Less than 50% of the money deposited has been wagered.")
      )
      setAddress("")
      setAmount("")
    } else if (
      responseWithdraw.status === 400 &&
      responseWithdraw.data.code === errorCodes.WITHDRAW_INVALID_ETH_ADDRESS
    ) {
      setIsLoading(false)
      toast.error(
        t("Withdraw address is invalid"),
        t("You need a valid ETH address.")
      )
      setAddress("")
      setAmount("")
    } else if (
      responseWithdraw.status === 403 &&
      responseWithdraw.data.code === errorCodes.WITHDRAW_BONUS_NOT_WAGERED
    ) {
      setIsLoading(false)
      toast.error(
        t("You can't withdraw"),
        t("You need to finish your deposit bonus to withdraw your earnings.")
      )
      setAddress("")
      setAmount("")
    } else {
      toast.error(t("Not enough balance"), t("You have insufficient funds."))
      setIsLoading(false)
      setAddress("")
      setAmount("")
    }
    reset()
  }

  const onSubmit = (e) => {
    setAmount(e.amount)
    setAddress(e.address)
    handleWithdraw(e.address, e.amount, undefined)
  }

  const handleSubmitPin = (value) => {
    setShowPrompt2FAPinCard(false)
    handleWithdraw(amount, address, value)
  }

  handleSubmit(onSubmit)

  const handleCancelPin = () => {
    setShowPrompt2FAPinCard(false)
    setAddress("")
    setAmount("")
    setIsLoading(false)
  }

  const handleWithdraweErrorTemporary = () => {
    toast.error(
      t("Insufficient funds."),
      t(
        "You have insufficient funds to make a withdrawal. Thanks for your trust!"
      )
    )
    reset()
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const handleRedirectToHistoryModal = () => {
    searchParams.set("service", "history")
    searchParams.set("tab", "deposits")
    setSearchParams(searchParams)
  }

  const themeColors = getThemeColors()

  return (
    <Tabs.Body.Item>
      <PaymentMethodContext.Provider value={context}>
        <div>
          <CheckRightAlert
            is2FAActive={is2FAActive}
            isKycCompleted={kyc?.kycLevel1Complete}
            isVerified={isVerified}
          />
          <div className="text-sm ">
            <p className="text-gray-700 text-sm xs:text-base">
              {t(
                "We provides multiple payment methods and make sure that our third party payment providers do not use your data."
              )}
            </p>
            <p className="text-gray-700 text-sm xs:text-base font-bold">
              {t("The minimum amount to deposit is 30USD,")} <br />
              <span className="" style={{ color: themeColors?.tertiary }}>
                {t("Pick your payment method of choice and proceed !")}
              </span>
            </p>
          </div>
          {showPrompt2FAPinCard && (
            <Prompt2FAPINCard
              onSubmit={handleSubmitPin}
              onCancel={handleCancelPin}
            />
          )}

          <div className="mb-5">
            <div className="mt-3 flex flex-row xs:flex xs:flex-col xs:justify-center xs:items-center ">
              <div
                className="desktop:w-full xs:w-full flex flex-col  rounded-xl desktop:mr-2 xs:mb-2 desktop:mb-0 px-5 pt-4 "
                style={{
                  backgroundColor: themeColors?.lightTertiary,
                }}
                css={borderStyle(themeColors)}
              >
                <Accordion defaultActiveItem="crypto-withdrawal">
                  <Accordion.Item key="pay-with-crypto-currency">
                    <Accordion.Item.Header
                      id="crypto-withdrawal"
                      cryptosAccordion
                    >
                      <div className="text-[18px] crazy-sav-payment-title text-specialPrimary ml-4">
                        {t("Crypto Withdrawal")}
                      </div>
                    </Accordion.Item.Header>
                    <Accordion.Item.Content id="crypto-withdrawal">
                      <div className="payments-methods-cards   xs:grid-cols-3 tablet:grid-cols-6 justify-center gap-3 pb-3">
                        {cryptoPaymentMethods.map((method) => (
                          <div key={method.label}>
                            <PaymentCard id={method.method} isCrazySavMethod>
                              <div className="flex justify-between items-center">
                                <img
                                  fetchpriority="low"
                                  loading="lazy"
                                  decoding="async"
                                  className="w-auto h-[27px] mr-2"
                                  src={method.imageSource}
                                  alt={method.label}
                                />
                                <div className="flex-col">
                                  <p className="text-sm text-specialPrimary font-semibold custom-payment-card-title">
                                    {t(method.label)}
                                  </p>
                                  <p className="text-xs text-tertiary custom-payment-card-title">
                                    {method.subLabel}
                                  </p>
                                </div>
                              </div>
                            </PaymentCard>
                          </div>
                        ))}
                      </div>
                      <div className="pb-1">
                        <Controller
                          control={control}
                          name="address"
                          render={({ field, formState }) => (
                            <Input
                              type="text"
                              id="ethereum-address"
                              label="Wallet Address"
                              error={formState.errors?.address}
                              {...field}
                            />
                          )}
                        />
                        <div className="flex justify-between items-center text-xs text-gray-700 mt-1">
                          <span className="text-red-700 text-xs">
                            {errors?.address && errors.address.message}
                          </span>
                          <span>
                            {t("Estimated fees")}:{" "}
                            {formatter.format(calculateFees())}{" "}
                          </span>
                        </div>
                        <div className="relative my-3">
                          <Controller
                            control={control}
                            name="amount"
                            render={({ field, formState }) => (
                              <Input
                                id="amount"
                                label="Amount"
                                onKeyDown={keyPressHanlder}
                                value={
                                  amount !== "" ? formatter.format(amount) : ""
                                }
                                error={formState.errors?.amount}
                                {...field}
                              />
                            )}
                          />
                          <div className="flex justify-between items-center text-xs text-gray-700 mt-1">
                            {errors?.amount && (
                              <span className="text-red-700 text-xs">
                                {" "}
                                {errors.amount.message}
                              </span>
                            )}
                            <span>Min: $10.00</span>{" "}
                            <span>
                              {t("Balance")}: {formatter.format(balance)}
                            </span>
                          </div>
                          <div className="absolute top-[6px] right-2">
                            <FontAwesomeIcon
                              icon={faDollarSign}
                              className=" text-white w-5 h-5 bg-specialFontBlue rounded-full p-1"
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Item.Content>
                  </Accordion.Item>
                  <Accordion.Item key="wire-transfer">
                    <div className="mt-4">
                      <Accordion.Item.Header
                        id="pay-with-crazy-sav"
                        cryptosAccordion
                      >
                        <div className="text-[18px] crazy-sav-payment-title text-specialPrimary ml-4">
                          {t("Wire Transfer")}
                        </div>
                      </Accordion.Item.Header>
                      <Accordion.Item.Content id="pay-with-crazy-sav">
                        <div className="pb-3 mt-5">
                          <Controller
                            control={control}
                            name="iban"
                            render={({ field }) => (
                              <Input
                                id="iban"
                                label={t("Insert your IBAN")}
                                onKeyDown={keyPressHanlder}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div className="relative pb-3">
                          <Controller
                            control={control}
                            name="amount"
                            render={({ field }) => (
                              <Input
                                id="amount"
                                label={t("Amount")}
                                onKeyDown={keyPressHanlder}
                                value={
                                  amount !== "" ? formatter.format(amount) : ""
                                }
                                {...field}
                              />
                            )}
                          />
                          <div className="flex justify-between items-center text-xs text-gray-700 mt-2">
                            <span>Min: $10.00</span>{" "}
                            <span>
                              {t("Balance")}: {formatter.format(balance)}
                            </span>
                          </div>
                          <div className="absolute top-[6px] right-2">
                            <FontAwesomeIcon
                              icon={faDollarSign}
                              className=" text-white w-5 h-5 bg-specialFontBlue rounded-full p-1"
                            />
                          </div>
                        </div>
                      </Accordion.Item.Content>
                    </div>
                  </Accordion.Item>
                </Accordion>
                <div className="mt-5 mb-2 flex justify-end items-center">
                  {!isLoading ? (
                    <Button.Primary
                      size="small"
                      /* onSubmit={handleSubmit(onSubmit)}
                      onClick={handleSubmit(onSubmit)} */
                      onSubmit={handleWithdraweErrorTemporary}
                      onClick={handleWithdraweErrorTemporary}
                    >
                      <span className="w-full font-semibold">
                        {" "}
                        {t("Withdraw")}
                      </span>
                    </Button.Primary>
                  ) : (
                    <Spinner />
                  )}
                  <Button.Secondary
                    size="small"
                    onClick={handleRedirectToHistoryModal}
                  >
                    <span className="text-tertiary font-semibold">
                      {t("History")}
                    </span>
                  </Button.Secondary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PaymentMethodContext.Provider>
    </Tabs.Body.Item>
  )
}

const TipTab = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [amount, setAmount] = useState(0)
  const [amountErrors, setAmountErrors] = useState()
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const formOptions = { resolver: yupResolver(tipSchema) }
  const [searchParam, setSearchParam] = useSearchParams()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm(formOptions)

  const { balance, fixedFees, percentageFees } = useSelector(
    (state) => state.wallet
  )
  const calculateFees = () => amount * percentageFees + fixedFees
  const onSubmit = async (data) => {
    if (parseFloat(amount, 10) < 10) {
      setAmountErrors("The amount specified is less than the minimum amount")
      return
    }
    if (!amount) {
      setAmountErrors("Please enter an amount")
      return
    }
    setAmountErrors()
    setAmount(0)
    setName(data.name)
    setIsLoading(true)
    const responseTip = await postTip(data.name, amount)
    if (responseTip.status === 201) {
      toast.success(
        t("Your tip is on its way !"),
        t("Tip sent successfully!", {
          username: data.name,
          amount,
        })
      )
      setIsLoading(false)
      reset()
      dispatch(getUser())
      searchParam.delete("tab")
      setSearchParam(searchParam)
      searchParam.delete("service")
      setSearchParam(searchParam)
    } else if (
      responseTip.status === 404 &&
      responseTip.data.code === "user-does-not-exist"
    ) {
      toast.error(
        t("User does not exist!"),
        t(
          "The username of the player you want to send tips to does not exist. Please try with another one !"
        )
      )
      setIsLoading(false)
    } else if (
      responseTip.status === 403 &&
      responseTip.data.code === "insufficient-balance"
    ) {
      toast.error(
        t("Insufficient balance."),
        t(
          "You have insufficient balance to send tips to other users . Make your deposit and profit now !"
        )
      )
      setIsLoading(false)
    } else if (
      responseTip.status === 403 &&
      responseTip.data.code === "bonus-not-wagered"
    ) {
      toast.error(
        t("Wager is not reached."),
        t(
          "You can’t send tips to another user until you reach your full wager. Please make more bets !"
        )
      )
      setIsLoading(false)
    }
    reset()
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const handleRedirectToHistoryModal = () => {
    searchParams.set("service", "history")
    setSearchParams(searchParams)
  }

  const themeColors = getThemeColors()

  return (
    <Tabs.Body.Item>
      <div className="w-full">
        <div className="my-2">
          <p className="text-sm xs:text-xs text-gray-800">
            {t(
              "Your balance remaining after tip must be $10.00 or greater. You just need the username to send them a tip and spread the fun."
            )}
          </p>
          <p
            className="text-sm xs:text-xs font-bold"
            style={{ color: themeColors?.tertiary }}
          >
            {t("Send tips to your friends and enjoy our Casino together !")}
          </p>
        </div>
        <div className="mt-4 mb-3 flex flex-row xs:flex xs:flex-col xs:justify-center xs:items-center  ">
          <div
            className="desktop:w-full xs:w-full  rounded-xl px-3 py-4 "
            style={{
              backgroundColor: themeColors?.lightTertiary,
            }}
            css={borderStyle(themeColors)}
          >
            <div className="px-3">
              <h1 className="mt-5 mb-3 text-specialPrimary font-semibold">
                {t("Name")}
              </h1>
              <div className="pb-1">
                <Controller
                  control={control}
                  name="name"
                  render={({ field, formState }) => (
                    <Input
                      name="playerName"
                      value={name}
                      type="text"
                      id="player-name"
                      label={t("Player name")}
                      error={formState.errors?.name}
                      {...field}
                    />
                  )}
                />
                <div className="flex justify-between items-center text-xs text-gray-700 mt-1">
                  <span className="text-red-700 text-xs">
                    {errors?.name && errors.name.message}
                  </span>
                  <span>
                    {t("Estimated fees")}: {formatter.format(calculateFees())}{" "}
                  </span>
                </div>
                <h1 className="mt2 mb-3 text-specialPrimary font-semibold">
                  {t("Amount")}
                </h1>
                <div className="relative my-3">
                  <Input
                    id="amount"
                    label={t("Amount")}
                    type="number"
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    error={amountErrors}
                  />
                  <div className="flex justify-between items-center text-xs text-gray-700 mt-1">
                    {amountErrors && (
                      <span className="text-red-700 text-xs">
                        {" "}
                        {amountErrors}
                      </span>
                    )}
                    <span>Min: $10.00</span>{" "}
                    <span>
                      {t("Balance")}: {formatter.format(balance)}
                    </span>
                  </div>
                  <div className="absolute top-[6px] right-2">
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      className=" text-white w-5 h-5 bg-specialFontBlue rounded-full p-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 mb-2 flex justify-end items-center">
              {!isLoading ? (
                <>
                  <Button.Primary
                    size="small"
                    onSubmit={handleSubmit(onSubmit)}
                    onClick={handleSubmit(onSubmit)}
                  >
                    <span className="w-full font-bold special-tips-modal-text">
                      {t("Send Tip")}
                    </span>
                  </Button.Primary>
                  <Button.Secondary
                    size="small"
                    onClick={handleRedirectToHistoryModal}
                  >
                    <span className="text-tertiary font-semibold special-tips-modal-text">
                      {t("History")}
                    </span>
                  </Button.Secondary>
                </>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </div>
    </Tabs.Body.Item>
  )
}

const BuyCryptoTab = () => {
  const { t, i18n } = useTranslation()
  const [walletAddress, setWalletAddress] = useState("")

  const { data: countryCode } = useQuery(
    ["userLocation"],

    () => getCountryCode(),
    {
      refetchOnMount: false,
      onError: () => {
        logger("error occurred with getting user Location !")
      },
    }
  )

  const getDynamicCurrency = () => (countryCode === "US" ? "ETH" : "TRX")

  const getWalletQuery = useQuery({
    queryKey: [
      "getWalletWithCurrency",
      countryCode ? getDynamicCurrency() : null,
    ],
    queryFn: () => getWalletWithCurrency(getDynamicCurrency()),
    enabled: !!countryCode,
    refetchOnMount: true,
    onSuccess: ({ data }) => {
      logger(data)
      setWalletAddress(data.wallet_address)
    },
    onError: (error) => {
      logger(error)
      if (
        error.status === 400 &&
        error.data.code === "cryptocurrency-not-exist"
      ) {
        toast.error("Invalid cryptocurrency.", "Invalid cryptocurrency.")
      }
    },
  })

  useEffect(() => {
    if (countryCode) {
      getWalletQuery.refetch()
    }
  }, [countryCode])

  const dynamicWalletAddress = {
    AU: `https://widget.changelly.com?from=aud&to=trx&amount=150&address=${walletAddress}&fromDefault=aud&toDefault=trx&merchant_id=86ZcHIjCnhWPS5f8&payment_id=&v=3&type=no-rev-share&color=7232CD&headerId=1&logo=hide&buyButtonTextId=1&readOnlyDestinationAddress=true`,
  }
  const getDynamicWalletAddress = () =>
    dynamicWalletAddress[countryCode]
      ? dynamicWalletAddress[countryCode]
      : `https://widget.changelly.com?from=eur&to=trx&amount=100&address=${walletAddress}&fromDefault=eur&toDefault=trx&merchant_id=86ZcHIjCnhWPS5f8&payment_id=&v=3&type=no-rev-share&color=7232CD&headerId=1&logo=hide&buyButtonTextId=1&readOnlyDestinationAddress=true`

  const themeColors = getThemeColors()

  const languageSelected = i18n.language?.split("-")[0]

  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    if (walletAddress) {
      setIsFetching(false)
    }
  }, [walletAddress])

  logger(languageSelected)
  logger(themeColors)

  const [amount, setAmount] = useState(100)

  const dynamicOnRampUrl = {
    US: `https://widget.uniramp.com/?api_key=pk_prod_eb0suFktOsnpthQYX5LXoMXIychV7Ofv&onramp_hybrid=true&onramp_chain=eth&onramp_wallet=${walletAddress}`,
    GB: `https://widget.uniramp.com/?api_key=pk_prod_eb0suFktOsnpthQYX5LXoMXIychV7Ofv&onramp_hybrid=true&onramp_chain=trx&onramp_wallet=${walletAddress}`,
  }

  const checkoutToOnRampUrl = () => {
    window.open(dynamicOnRampUrl[countryCode], "_blank")
  }

  return (
    <Tabs.Body.Item>
      <div className="w-full">
        <div className="my-2">
          {countryCode === "US" || countryCode === "GB" ? (
            <>
              <h1 className="text-xl font-extrabold text-tertiary">
                {t("Don't have any cryptocurrency?")}
              </h1>
              <p className="text-md xs:text-base text-gray-800 my-3">
                {t(
                  "Complete these simple steps and the funds will be automatically transferred to your selected Ojori wallet as shown below."
                )}
              </p>
            </>
          ) : (
            <>
              <p className="text-sm xs:text-base text-gray-800">
                {t("Buy crypto instantly with your")}{" "}
                <span className="font-bold">{t("bank card")}</span>{" "}
                {t("or via")}
                <span className="font-bold"> ApplePay</span>,{" "}
                {t(
                  "and have it sent to your Ojori account. Simply select the desired amount and proceed to payment!"
                )}
              </p>
              <p className="text-sm xs:text-base text-gray-800">
                {t(
                  "If you want to buy crypto it's easy but if you want to know how it works read"
                )}
                &nbsp;
                <a
                  href={`${buyCryptoWithinFiveMinutesArticles[languageSelected]}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm font-bold"
                  style={{ color: themeColors?.tertiary }}
                >
                  {t("this help article")}.
                </a>
              </p>
            </>
          )}
        </div>
        {countryCode === "US" || countryCode === "GB" ? (
          <div className="text-black w-full flew justify-center items-center">
            <div
              className="desktop:w-[60%] xs:w-full flex flex-col  rounded-xl mx-auto xs:mb-2 desktop:mb-0  "
              style={{
                backgroundColor: themeColors?.lightTertiary,
              }}
              css={borderStyle(themeColors)}
            >
              <div className="my-2 px-5 pt-2">
                <div className="relative my-3">
                  <Input
                    id="amount"
                    label={t("Amount")}
                    type="number"
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    error={amount < 30}
                  />
                  <div className="flex justify-between items-center text-xs text-gray-700 mt-1">
                    <span>Min: $30.00</span>{" "}
                  </div>
                  <div className="flex justify-between items-center text-xs text-gray-700 mt-1">
                    {amount < 30 && (
                      <span className="text-red-700 text-xs">
                        The minimum amount to buy crypto is $30.00
                      </span>
                    )}
                  </div>
                  <div className="absolute top-[6px] right-2">
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      className=" text-white w-5 h-5 bg-specialFontBlue rounded-full p-1"
                    />
                  </div>
                </div>
              </div>
              <div className="px-5 pt-2">
                <Button.Primary
                  fullWidth
                  onClick={checkoutToOnRampUrl}
                  disabled={amount < 30}
                >
                  Buy
                </Button.Primary>
              </div>
              <div className="mt-8 px-5 py-2 bg-tertiary rounded-br-xl rounded-bl-xl">
                <div className="flex justify-between items-center xs:flex  xs:my-5 desktop:my-auto p-2">
                  <img
                    fetchpriority="low"
                    src={visaAsset}
                    alt="master-card-asset"
                    className=" desktop:h-7 mx-3 xs:w-auto xs:h-5"
                  />
                  <img
                    fetchpriority="low"
                    src={visaVectorAsset}
                    alt="visa-card-asset"
                    className=" desktop:h-6 mx-3 xs:w-auto xs:h-5 "
                  />
                  <img
                    fetchpriority="low"
                    src={thumbAsset}
                    alt="google-pay-asset"
                    className=" desktop:h-9 mx-3 xs:w-auto xs:h-5 "
                  />
                  <img
                    fetchpriority="low"
                    src={applePayAsset}
                    alt="apple-pay-asset"
                    className=" desktop:h-8 mx-3 xs:w-auto xs:h-5 "
                  />
                </div>
              </div>
            </div>
          </div>
        ) : isFetching ? (
          <Spinner />
        ) : (
          <div className="mt-7 rounded-xl flex justify-center items-center">
            <CustomIframe
              title="Changelly-Buy-Crypto"
              width="100%"
              height="400px"
              frameBorder="none"
              allow="camera"
              src={getDynamicWalletAddress()}
            />
          </div>
        )}
      </div>
    </Tabs.Body.Item>
  )
}

// TODO: to copy the code to trigger the copy of the ethereum address.

const CashierModal = ({ activeService, handleCloseModal, activeTab }) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectTab = (tab) => {
    searchParams.set("tab", tab)
    setSearchParams(searchParams)
  }

  const themeColors = getThemeColors()

  return (
    <Modal
      activeModal={activeService === serviceName}
      closeModal={handleCloseModal}
    >
      <div className="xs:h-[680px] xs:overflow-auto modal-cashier-view">
        <MediaQuery maxWidth={959}>
          <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
            <h1
              className=" text-xl leading-5 font-medium px-7"
              style={{ color: themeColors?.tertiary }}
            >
              {t("Cashier")}
            </h1>
            <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={960}>
          <div className="flex justify-between items-center">
            <h1 className="pl-6 " style={{ color: themeColors?.tertiary }}>
              {t("Cashier")}
            </h1>
            <Modal.Header handleCloseModal={handleCloseModal} />
          </div>
        </MediaQuery>
        <div>
          <Tabs selectedIndex={tabIndexes[activeTab] || tabIndexes.DEPOSIT}>
            <div>
              <Tabs.Header>
                <div>
                  <Tabs.Header.Item onClick={() => selectTab("deposit")}>
                    <span className="desktop:text-md xs:text-sm">
                      {t("Deposit")}
                    </span>
                  </Tabs.Header.Item>
                  <Tabs.Header.Item onClick={() => selectTab("buy-crypto")}>
                    <span className="desktop:text-md xs:text-sm">
                      {t("Buy")}
                    </span>
                  </Tabs.Header.Item>
                  <Tabs.Header.Item onClick={() => selectTab("withdraw")}>
                    <span className="desktop:text-md xs:text-sm">
                      {t("Withdraw")}
                    </span>
                  </Tabs.Header.Item>
                  <Tabs.Header.Item onClick={() => selectTab("tip")}>
                    <span className="desktop:text-md xs:text-sm">
                      {t("Tip")}
                    </span>
                  </Tabs.Header.Item>
                </div>
              </Tabs.Header>
              <Tabs.Body>
                <div>
                  <DepositTab />
                  <BuyCryptoTab />
                  <WithdrawTab />
                  <TipTab />
                </div>
              </Tabs.Body>
            </div>
          </Tabs>
        </div>
      </div>
    </Modal>
  )
}

CashierModal.defaultProps = {
  activeService: "cashier",
  activeTab: tabIndexes.deposit,
}
CashierModal.propTypes = {
  activeService: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
}

export default CashierModal
